import React from 'react'
import Layout from "../components/layout";
import Timer from "../components/timer"
import Particles from 'react-particles-js';
import logo from '../images/turtcenter.png'
import { createClient } from '@supabase/supabase-js'

const supabase = createClient('https://ladrpakymswoxtqlkafd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxhZHJwYWt5bXN3b3h0cWxrYWZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM4Mjg0NTksImV4cCI6MjAxOTQwNDQ1OX0.t9xkNcAJu6lQs5H-KW8etXN-HQNSkUR9i4lE3gADL6w')

const particlesOptions = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    }
  }
};

class IndexPage extends React.Component {
  state={
    email: '',
    submitted: false
  }
  handleV = async (e) => {
    this.setState({ email: e })
    this.setState({ submitted: true })

    const { error } = await supabase
    .from('emails')
    .insert({ email: e })

    console.log(error)
  }
  render() {
    return(
      <Layout>
        <div className="bgimg">
          <div className="bg-layout">
          <img src={logo} height={150}></img>
            <div className="middle">
              <div className="content">
                
                {!this.state.submitted ?
                <div>
                <h1 className="h1">you're early...</h1>
                <br></br>
                <input name="email" id='emailinput' type="text" placeholder="email address" />
                <button type='submit' className="button" onClick={() => this.handleV(document.getElementById('emailinput').value)}>submit</button> </div> : <h1>welcome to the pond.</h1>}
                <div id="mc_embed_signup">
              </div>
                <div className="btn-group">
                  
                  {/*Twitter*/}
                  <a href="https://twitter.com/thepond_app" target='_blank' className="button-social"><svg style={{width:24, height:24}} viewBox="0 0 24 24">
                    <path fill="#75bc5e" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                  </svg></a>
                  

                </div>
              </div>
            </div>
            <div className="bottomleft">
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
